import { useStaticQuery, graphql } from 'gatsby'
import { get } from '../../utils/get'
import { getLink } from './getLink'
import { getRichText } from './getRichText'
// import { getSlug } from '../../utils/getSlug'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const getSiteConfig = () => {
  const configData = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        prismicConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            nav_primary {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            nav_secondary {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            footer_contact_heading {
              raw
            }
            footer_contact_text {
              raw
            }
            footer_notes {
              raw
            }
            download_file {
              url
            }
            download_button_label
            download_file_name
          }
        }
      }
    `
  )

  const gatsbyConfig = configData.gatsbyConfig.siteMetadata || {}
  const prismicConfig = configData.prismicConfig.data || {}

  const primaryNav = []
  if (prismicConfig.nav_primary) {
    prismicConfig.nav_primary.forEach((item, index) => {
      primaryNav.push(getLink(item.link, item.title, `navPrimary_${index}`))
    })
  }

  const secondaryNav = []
  if (prismicConfig.nav_secondary) {
    prismicConfig.nav_secondary.forEach((item, index) => {
      secondaryNav.push(getLink(item.link, item.title, `navSecondary_${index}`))
    })
  }

  return {
    metadata: {
      title: prismicConfig.title || gatsbyConfig.title,
      description: prismicConfig.meta_description || gatsbyConfig.description,
      subtitle: prismicConfig.meta_subtitle || gatsbyConfig.subtitle,
      image: get(prismicConfig, 'meta_image.url') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    download: {
      url: get(prismicConfig, 'download_file.url'),
      name: prismicConfig.download_file_name,
      buttonLabel: prismicConfig.download_button_label,
    },
    footer: {
      contactHeading: getRichText(
        prismicConfig,
        'footer_contact_heading.raw',
        'p'
      ),
      contactText: getRichText(prismicConfig, 'footer_contact_text.raw'),
      notes: getRichText(prismicConfig, 'footer_notes.raw'),
    },
    header: {
      primaryNav,
      secondaryNav,
    },
  }
}
