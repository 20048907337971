import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { createPortal } from 'react-dom'
import { navigate } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Btn } from '../Btn'
import { Link } from '../Link'
import { Container } from '../Container'
import styles from './Menu.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const Menu = ({
  currentPath,
  download,
  onClickNav,
  isVisible,
  primaryNav,
  secondaryNav,
}) => {
  const ref = useRef()
  const handleClickDownload = (url, filename) => saveAs(url, filename)

  const handleClickNav = (path) => {
    const isInternal = path ? /^\/(?!\/)/.test(path) : false
    if (isInternal) {
      onClickNav()
      setTimeout(() => {
        navigate(path)
      }, 300)
    } else if (path !== undefined) {
      console.log('not isInternal', path)
      window.location.href = path
    }
  }

  useEffect(() => {
    if (IS_BROWSER) {
      if (isVisible) {
        disableBodyScroll(ref.current, { reserveScrollBarGap: true })
      } else {
        enableBodyScroll(ref.current)
      }
    }
  }, [isVisible])

  if (IS_BROWSER) {
    return createPortal(
      <div ref={ref}>
        <AnimatePresence>
          {isVisible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={styles.el}
            >
              <Container className={styles.el__container}>
                <div className={styles.el__primaryNav}>
                  <ul className={styles.el__nav__list}>
                    {primaryNav.map((item) => (
                      <li key={item.id} className={styles.el__nav__item}>
                        <Link
                          href={item.url}
                          onClick={() => handleClickNav(item.url)}
                          className={styles.el__nav__link}
                          data-active={currentPath === item.url}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles.el__secondary}>
                  {download.url && (
                    <div className={styles.el__download}>
                      <Btn
                        icon="download"
                        onClick={() =>
                          handleClickDownload(download.url, download.name)
                        }
                      >
                        {download.buttonLabel}
                      </Btn>
                    </div>
                  )}
                  <div className={styles.el__secondary__nav}>
                    <ul className={styles.el__nav__list}>
                      {secondaryNav.map((item) => (
                        <li key={item.id} className={styles.el__nav__item}>
                          <Link
                            href={item.url}
                            onClick={() => handleClickNav(item.url)}
                            className={styles.el__nav__link}
                            data-active={currentPath === item.url}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Container>
            </motion.div>
          )}
        </AnimatePresence>
      </div>,
      document.body
    )
  }

  return null
}

Menu.defaultProps = {
  currentPath: undefined,
  download: {},
  onClickNav: () => {},
  isVisible: false,
  primaryNav: [],
  secondaryNav: [],
}

Menu.propTypes = {
  currentPath: PropTypes.string,
  download: PropTypes.object,
  onClickNav: PropTypes.func,
  isVisible: PropTypes.bool,
  primaryNav: PropTypes.array,
  secondaryNav: PropTypes.array,
}

export { Menu }
