import React from 'react'
import PropTypes from 'prop-types'
import '../styles/main.scss'
import { Metadata } from '../components/Metadata'
import { PageTransition } from '../components/PageTransition'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { useViewportHeight } from '../hooks/useViewportHeight'
import styles from './Layout.styles.scss'
import favicon from '../images/favicon.png'
import faviconDarkmode from '../images/favicon-darkmode.png'

const Layout = ({ site, page, children }) => {
  useViewportHeight()

  return (
    <>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={{ default: favicon, darkMode: faviconDarkmode }}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />
      <div className={styles.el}>
        <Header
          {...site.header}
          title={site.metadata.title}
          currentPath={page.path}
          download={site.download}
        />
        <main className={styles.el__main}>
          <PageTransition path={page.path}>{children}</PageTransition>
        </main>
        <Footer {...site.footer} />
      </div>
    </>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    download: {},
    favicon: undefined,
    header: {},
    footer: {},
  },
  page: {
    title: undefined,
    image: undefined,
    path: undefined,
  },
}

Layout.propTypes = {
  site: PropTypes.shape({
    metadata: PropTypes.object,
    download: PropTypes.object,
    favicon: PropTypes.string,
    header: PropTypes.object,
    footer: PropTypes.object,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

export default Layout
