import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../Container'
import { RichText } from '../RichText'
import styles from './Footer.styles.scss'
import LogoSvg from './images/logo.inline.svg'

const Footer = ({ contactHeading, contactText, notes }) => {
  return (
    <footer className={styles.el}>
      <Container>
        <div className={styles.el__primary}>
          {contactHeading && (
            <h3 className={styles.el__heading}>{contactHeading}</h3>
          )}
          {contactText && (
            <div className={styles.el__content}>
              <RichText>{contactText}</RichText>
            </div>
          )}
        </div>
        <div className={styles.el__secondary}>
          <div className={styles.el__logo}>
            <LogoSvg />
          </div>
          {notes && (
            <div className={styles.el__notes}>
              <RichText>{notes}</RichText>
            </div>
          )}
        </div>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  contactHeading: undefined,
  contactText: undefined,
  notes: undefined,
}

Footer.propTypes = {
  contactHeading: PropTypes.node,
  contactText: PropTypes.node,
  notes: PropTypes.node,
}

export { Footer }
