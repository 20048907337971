import React from 'react'
import { RichText } from 'prismic-reactjs'
import { get } from '../../utils/get'
import { linkResolver } from './linkResolver'
import { Link } from '../../components/Link'

// usage
// getRichText(object, 'path')

export const getRichText = (object, path, stripTag) => {
  const data = get(object, path)

  if (data && data.length && data[0].text.length) {
    return (
      <RichText
        render={data}
        htmlSerializer={(type, element, content, children) => {
          if (type === 'paragraph' && stripTag === 'p') {
            return (
              <React.Fragment key={`p-${element.text}`}>
                {children}
              </React.Fragment>
            )
          }
          if (type === 'hyperlink') {
            let url
            let target
            let download = false

            switch (element.data.link_type) {
              case 'Web':
                url = element.data.url
                target = element.data.target
                break
              case 'Document':
                url = linkResolver(element.data)
                break
              case 'Media':
                url = element.data.url
                download = true
                break
              default:
            }

            return (
              <Link
                key={`${element.data.id}-${content}`}
                to={url}
                target={target}
                download={download}
              >
                {content}
              </Link>
            )
          }

          return undefined
        }}
      />
    )
  }
  return undefined
}
