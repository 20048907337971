import React from 'react'
import PropTypes from 'prop-types'
import styles from './BtnMenu.styles.scss'

const BtnMenu = ({ onClick, ariaLabel, isActive }) => {
  return (
    <button
      className={styles.el}
      onClick={onClick}
      type="button"
      aria-label={ariaLabel}
      data-active={isActive}
    >
      <span className={styles.el__line} />
      <span className={styles.el__line} />
    </button>
  )
}

BtnMenu.defaultProps = {
  ariaLabel: 'Play',
  isActive: false,
  onClick: undefined,
}
BtnMenu.propTypes = {
  ariaLabel: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
export { BtnMenu }
