import React from 'react'
import PropTypes from 'prop-types'
import styles from './RichText.styles.scss'

const RichText = ({ children, headingStyles, listStyles }) => {
  return (
    <div
      className={styles.el}
      data-headings={headingStyles}
      data-lists={listStyles}
    >
      {children}
    </div>
  )
}

RichText.defaultProps = {
  headingStyles: true,
  listStyles: true,
}

RichText.propTypes = {
  children: PropTypes.node.isRequired,
  headingStyles: PropTypes.bool,
  listStyles: PropTypes.bool,
}

export { RichText }
