import React, { useState } from 'react'
import useMedia from 'use-media'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '../Container'
import { Link } from '../Link'
import { BtnMenu } from '../BtnMenu'
import { Menu } from '../Menu'
import animations from './Header.animations'
import styles from './Header.styles.scss'
import LogoSvg from './images/logo.inline.svg'

const Header = ({ currentPath, download, title, primaryNav, secondaryNav }) => {
  const isMobile = useMedia({ maxWidth: '1024px' })
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleToggleMenu = () => setIsMenuOpen((prevState) => !prevState)
  const handleCloseMenu = () => setIsMenuOpen(false)

  return (
    <>
      <motion.header className={styles.el} initial="hidden" animate="visible">
        <Container>
          <div className={styles.el__inner}>
            <motion.h1 className={styles.el__logo} variants={animations.logo}>
              <Link to="/" aria-label={title}>
                <LogoSvg />
              </Link>
            </motion.h1>
            <motion.nav className={styles.el__nav} variants={animations.nav}>
              <div className={styles.el__primaryNav}>
                <ul className={styles.el__nav__list}>
                  {primaryNav.map((item) => (
                    <li key={item.id} className={styles.el__nav__item}>
                      <Link
                        to={item.url}
                        target={item.target}
                        className={styles.el__nav__link}
                        data-active={currentPath === item.url}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.el__secondaryNav}>
                <ul className={styles.el__nav__list}>
                  {secondaryNav.map((item) => (
                    <li key={item.id} className={styles.el__nav__item}>
                      <Link
                        to={item.url}
                        target={item.target}
                        className={styles.el__nav__link}
                        data-active={currentPath === item.url}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.nav>
            {isMobile && (
              <BtnMenu onClick={handleToggleMenu} isActive={isMenuOpen} />
            )}
          </div>
        </Container>
      </motion.header>
      {isMobile && (
        <Menu
          currentPath={currentPath}
          isVisible={isMenuOpen}
          onClickNav={handleCloseMenu}
          primaryNav={primaryNav}
          secondaryNav={secondaryNav}
          download={download}
        />
      )}
    </>
  )
}

Header.defaultProps = {
  currentPath: undefined,
  download: {},
  title: undefined,
  primaryNav: [],
  secondaryNav: [],
}

Header.propTypes = {
  currentPath: PropTypes.string,
  download: PropTypes.object,
  title: PropTypes.string,
  primaryNav: PropTypes.array,
  secondaryNav: PropTypes.array,
}

export { Header }
