import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

// this is exported for use in other components
const pageTransitionDuration = 0.25

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionDuration,
      delay: pageTransitionDuration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: pageTransitionDuration },
  },
}

const PageTransition = ({ children, path }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={path}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

PageTransition.defaultProps = {
  children: undefined,
  path: undefined,
}

PageTransition.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
}

export { PageTransition, pageTransitionDuration }
