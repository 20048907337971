/* eslint react/button-has-type: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Btn.styles.scss'
import DownloadSVG from './images/icon-download.inline.svg'

const Btn = ({
  children,
  download,
  href,
  icon,
  onClick,
  Tag,
  target,
  type,
}) => {
  let typeOutput = type
  if (typeOutput === undefined && Tag === 'button') {
    typeOutput = 'button'
  }

  return (
    <Tag
      type={typeOutput}
      className={styles.el}
      onClick={onClick}
      href={href}
      download={download}
      target={target}
    >
      <span className={styles.el__wrapper}>
        {icon && (
          <span className={styles.el__icon}>
            {icon === 'download' && <DownloadSVG />}
          </span>
        )}
        {children && <span className={styles.el__text}>{children}</span>}
      </span>
    </Tag>
  )
}

Btn.defaultProps = {
  children: undefined,
  download: false,
  href: undefined,
  icon: undefined,
  onClick: () => {},
  Tag: 'button',
  target: undefined,
  type: undefined,
}

Btn.propTypes = {
  children: PropTypes.node,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  href: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  Tag: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
}

export { Btn }
